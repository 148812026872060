import { useDispatch, useSelector } from "react-redux";
import {
  organizationActions,
  organizationSelectors
} from "../../../state/organization";
import { Spinner } from "../../spinner";
import { Header } from "../header";
import { AppContext, ManagementPortalContext } from "../../../context";
import React, { useContext, useEffect, useState } from "react";
import { sessionSelectors } from "../../../state/session";
import { Tile } from "../../tile";
import { featureFlagsSelectors } from "../../../state/featureFlags";
import { useI18n } from "../../../helpers/hooks/useI18n";

const ENABLED_POLICIES = [
  "disallow_external_read",
  "disallow_external_write",
  "documents_require_password",
  "all_org_admins_get_admin_access"
];

export const SUPER_LOCKED_DOWN_ORG_POLICY =
  "only_org_admins_can_create_workspace";

export const TWO_FACTOR_AUTH_POLICY = "enforce_two_factor_login";

const EXPLICIT_EXTERNAL_ACCESS_POLICY = "allow_explicit_external_access";

type PolicyInfo = {
  policy: string;
  description: string;
  borderClass: string;
};

const Policy = (props: PolicyInfo): JSX.Element => {
  const context: ManagementPortalContext = useContext(AppContext);
  const dispatch = useDispatch();
  const orgId = useSelector(sessionSelectors.orgId);
  const activePolicies = useSelector(organizationSelectors.policies.active);
  const showExplicitExternalAccessPolicy = useSelector(
    featureFlagsSelectors.showExplicitExternalAccessPolicy
  );

  const policyTitle =
    context.Localized.string("POLICIES." + props.policy + "_title") ||
    props.policy;
  const policyDesc =
    context.Localized.string("POLICIES." + props.policy) || props.description;

  const checkPolicy = (
    e: React.ChangeEvent<HTMLInputElement>,
    key?: string
  ) => {
    var newPolicies;
    if (e.target.checked) {
      newPolicies = new Set([key || props.policy, ...activePolicies]);
    } else {
      newPolicies = new Set(
        activePolicies.filter(p => p !== (key || props.policy))
      );
    }
    dispatch(organizationActions.saveOrganizationPolicies(orgId, newPolicies));
  };

  return (
    <div
      className={`p-6 flex flex-col content-center text-lg ${props.borderClass}`}
    >
      <div className="flex justify-start content-center">
        <input
          data-test-id={`checkbox-${props.policy}`}
          className="mr-4 mt-2 leading-4 self-start"
          id={props.policy}
          type="checkbox"
          value={props.policy}
          checked={activePolicies.includes(props.policy)}
          onChange={checkPolicy}
        />
        <label htmlFor={props.policy} className="flex flex-col justify-start">
          <span className="text-lg font-bold">{policyTitle}</span>
          <span className="mt-1 text-base">{policyDesc}</span>
        </label>
      </div>
      {props.policy === "disallow_external_read" &&
        (showExplicitExternalAccessPolicy ||
          activePolicies.includes(EXPLICIT_EXTERNAL_ACCESS_POLICY)) && (
          <div className="flex justify-start content-center pl-8 pr-8 pt-4">
            <input
              disabled={!activePolicies.includes("disallow_external_read")}
              data-test-id={`checkbox-${EXPLICIT_EXTERNAL_ACCESS_POLICY}`}
              className="mr-4 mt-2 leading-4 self-start"
              id={EXPLICIT_EXTERNAL_ACCESS_POLICY}
              type="checkbox"
              value={EXPLICIT_EXTERNAL_ACCESS_POLICY}
              checked={activePolicies.includes(EXPLICIT_EXTERNAL_ACCESS_POLICY)}
              onChange={event =>
                checkPolicy(event, EXPLICIT_EXTERNAL_ACCESS_POLICY)
              }
            />
            <label
              htmlFor={props.policy}
              className="flex flex-col justify-start"
            >
              <span className="text-base">
                {context.Localized.string(
                  "POLICIES.allow_explicit_external_access"
                )}
              </span>
            </label>
          </div>
        )}
    </div>
  );
};

const PolicyList = (): JSX.Element => {
  const supported = useSelector(organizationSelectors.policies.supported);
  const policies = [...ENABLED_POLICIES];
  const t = useI18n("POLICIES.");

  if (
    useSelector(featureFlagsSelectors.orgPolicyOnlyOrgAdminsCanCreateWorkspace)
  ) {
    policies.push(SUPER_LOCKED_DOWN_ORG_POLICY);
  }

  if (useSelector(featureFlagsSelectors.useTwoFactorAuth)) {
    policies.push(TWO_FACTOR_AUTH_POLICY);
  }

  return (
    <Tile>
      <div className="pb-8 pt-8 pl-14 border-b border-colorPrimary">
        {t("DEFAULT_ACCESS_INFO")}
      </div>
      {policies.map((policy, index) => {
        return (
          <Policy
            key={policy}
            policy={policy}
            description={supported[policy]}
            borderClass={
              index !== policies.length - 1
                ? "border-b border-colorPrimary"
                : ""
            }
          />
        );
      })}
    </Tile>
  );
};

export const Policies = (): JSX.Element => {
  const dispatch = useDispatch();

  const orgId = useSelector(sessionSelectors.orgId);
  const context: ManagementPortalContext = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const fetched = useSelector(organizationSelectors.policies.fetched);
  const isLoading = useSelector(
    () => !loaded && organizationSelectors.policies.loading
  );

  useEffect(() => {
    dispatch(organizationActions.getOrganizationPolicies(orgId));
  }, [dispatch, orgId]);

  useEffect(() => {
    if (fetched === true) {
      setLoaded(true);
    }
  }, [fetched]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="container flex-1 flex flex-col">
      <Header title={context.Localized.string("POLICIES.TITLE")} />
      <PolicyList />
    </div>
  );
};
